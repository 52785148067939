
import { Observable } from "../hooks/observable";
import { CostItemsView } from "../models/CostItemView";
import IMessage from "../models/IMessage";
import { ApiResponse } from "../models/ApiResponse";

export default class GlobalStateService {

  public readonly costItemsView = new Observable<CostItemsView>(CostItemsView.All);
  public readonly isDetailsPanelOpen = new Observable<boolean>(false);
  public readonly selectedItems = new Observable<any[]>([]);
  public readonly columnsMetaData = new Observable<any[]>([]);
  public readonly isPanelMaximised = new Observable<boolean>(false);
  public readonly isPanelLoading = new Observable<boolean>(false);
  public readonly pendingItems = new Observable<ApiResponse>({} as ApiResponse);
  public readonly message = new Observable<IMessage>({
    intent: 'success',
    message: ''
  });
  public readonly currentCostItemId = new Observable<string> ("");
  public readonly currentCostItemComment = new Observable<string> ("");
  
  public resetObservables() {
    this.costItemsView.set(CostItemsView.All);
  }

  public setIsPanelMaximised(maximised: boolean) {
    this.isPanelMaximised.set(maximised);
  }

  public setIsPanelLoading(loading: boolean) {
    this.isPanelMaximised.set(loading);
  }

  public setPendingItems(response:ApiResponse) {
    this.pendingItems.set(response);
  }

  public SetCurrentCostItemId(itemId: string) {
    this.currentCostItemId.set(itemId);
  }

  public SetCurrentCostItemComment(comment: string) {
    this.currentCostItemComment.set(comment);
  }

  public SetColumnMetaData(meta: any) {
    this.columnsMetaData.set(meta);
  }

  public SetAppMessage(message: IMessage) {
    this.message.set(message);
  }

  public SetPanelDetailsOpen(isOpen: boolean) {
    this.isDetailsPanelOpen.set(isOpen);
  }

  public SetSelectedItems(items: any[]) {
    this.selectedItems.set(items);
  }

  public SetCostItemsView(view: CostItemsView) {
    this.costItemsView.set(view);
  }
}
