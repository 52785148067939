import { useContext, useEffect, useState } from "react";
import { TeamsFxContext } from "../Context";
import { ViewDropdown } from "../ViewDropdown";
import { DynamicTable } from "../Table/DynamicTable";
import { makeStyles, Button, Spinner } from "@fluentui/react-components";
import { Dismiss32Filled, Checkmark32Filled } from "@fluentui/react-icons";
import { CostItemsView } from "../../models/CostItemView";
import { DetailsPanel } from "../DetailsPanel/DetailsPanel";
import { AppMessageBar } from "../MessageBar/MessageBar";
import { globalStateService } from "../../services/services";
import useObservable from "../../hooks/useObservable";
import { callFunction } from "../../services/requestService";
import { DataItem } from "../../models/ApiResponse";
import { ActionName, Actions } from "../../models/IActions";
import ActionPayload from "../../models/ActionPayload";
import { ActionResponseObject } from "../../models/IActionResponse";
const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    minWidth: "min-content",
  },
});

export default function PendingItems() {
  const { themeString } = useContext(TeamsFxContext);
  const message = useObservable(globalStateService.message);
  const selectedItems = useObservable(globalStateService.selectedItems);
  const pendingItems = useObservable(globalStateService.pendingItems);
  const isPanelOpen = useObservable(globalStateService.isDetailsPanelOpen);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const styles = useStyles();
  //const cred = TeamsUserCredentialContext.getInstance().getCredential();
  //cred.getToken("").then((token) => console.log(token));

  const commentsRequired = (actionName: ActionName): boolean => {
    if (selectedItems.length > 0) {
      const items = selectedItems.map((i: string) =>
        pendingItems.data.find((d) => d["costItemId"].value === i)
      );
      if (items.length > 0) {
        const hasCommentRequired = items.some((item: any) => {
          const actions = item["actions"]?.properties as Actions;
          const action = actions.activities.find(
            (a) => a.actionName === actionName
          );
          return action && action.commentRequired && !item["comment"].value;
        });
        return hasCommentRequired;
      }
    }
    return false;
  };

  const submitCostItems = async (actionName: ActionName) => {
    let successCount = 0;
    let errorCount = 0;

    const promises = selectedItems.map((i: string) => {
      const item = pendingItems.data.find((d) => d["costItemId"].value === i);
      if (item) {
        const actions = item["actions"]?.properties as Actions;
        const action = actions.activities.find(
          (a) => a.actionName === actionName
        );
        if (action) {
          const requestBody: ActionPayload = {
            comments: item["comment"].value.toString(),
            activityType: action.activityType,
            costItemId: i,
          };
          return callFunction("POST", action.actionUrl, "", requestBody)
            .then((r) => {
              successCount += 1;
              return r;
            })
            .catch((error) => {
              errorCount += 1;
            });
        }
      }
    });

    const data = await (Promise as any).allSettled(promises);
    const consolidatedResults: ActionResponseObject[] = data
      .filter((e: any) => e.status === "fulfilled")
      .map((e: any) => e.value as ActionResponseObject);

    const failedItems = consolidatedResults.filter(
      (r) => r.objectActions[0].success === false
    );
    const successItems = consolidatedResults.filter(
      (r) => r.objectActions[0].success === true
    );
    if (failedItems.length > 0) {
      const items = pendingItems.data.filter((f) =>
        successItems.some(
          (a) => a.objectActions[0].objectId === f["costItemId"].value
        )
      );
      const itemNums = items.map((i) => i["costItemNum"].value);
      globalStateService.SetAppMessage({
        message: `Failed submitting the following cost items: ${itemNums.join(
          ", "
        )}`,
        intent: "error",
      });
    }

    if (successItems.length > 0) {
      const items = pendingItems.data.filter(
        (f) =>
          !successItems.some(
            (a) => a.objectActions[0].objectId === f["costItemId"].value
          )
      );
      const newPendingItems = { ...pendingItems, data: items };
      globalStateService.setPendingItems(newPendingItems);
      globalStateService.SetAppMessage({
        message: "Your selected items have been successfully submitted.",
        intent: "success",
      });
    }
  };

  const approveRejectItems = async (actionName: ActionName) => {
    try {
      setisLoading(true);
      const missingComments = commentsRequired(actionName);

      if (missingComments) {
        globalStateService.SetAppMessage({
          message: "Please provide comments for the selected cost items.",
          intent: "warning",
        });
      } else {
        await submitCostItems(actionName);
      }
    } catch {
      globalStateService.SetAppMessage({
        message: "We cannot submit your request, please try again.",
        intent: "error",
      });
    } finally {
      setisLoading(false);
    }
  };

  useEffect(() => {
    setisLoading(true);
    callFunction<any>("GET", "inbox/pendingapproval/costitems")
      .then((response) => {
        const updatedData = response.data.map((d: DataItem) => ({
          ...d,
          comment: { value: "" },
          isEditingComment: { value: false },
          commentsRequired: { value: false },
        }));
        globalStateService.setPendingItems({ ...response, data: updatedData });
        setisLoading(false);
      })
      .catch((erroe) => {
        globalStateService.SetAppMessage({
          intent: "error",
          message: "Error fetching cost item details",
        });
      })
      .finally(() => setisLoading(false));
  }, []);

  return (
    <div
      style={{ height: "100%" }}
      className={
        themeString === "default"
          ? "light"
          : themeString === "dark"
          ? "dark"
          : "contrast"
      }
    >
      <div
        style={{
          padding: "15px",
          textAlign: "right",
          width: "100%",
          display: "none",
        }}
      >
        <ViewDropdown defaultSelectedOptions={[`${CostItemsView.All}`]} />
      </div>
      {isLoading ? (
        <Spinner
          style={{ marginTop: "30px" }}
          size="huge"
          label="Please wait..."
        />
      ) : (
        <>
          {message.message && (
            <div
              style={{
                padding: "15px",
                width: "100%",
              }}
            >
              <AppMessageBar
                message={message}
                onDismiss={() =>
                  globalStateService.SetAppMessage({ message: "" })
                }
              />
            </div>
          )}
          {pendingItems?.data && pendingItems?.meta && (
            <>
              <div
                style={{
                  padding: message.message ? "0 0 0 25px" : "20px 0 0 25px",
                  width: "100%",
                }}
              >
                Welcome to the approval app for hiring managers. Use the table
                below to review and approve or reject timesheets and cost items
                quickly and easily. Simplify your workflow and keep everything
                organized in one place.
              </div>
              <div style={{ margin: "10px" }}>
                <DynamicTable
                  data={pendingItems?.data || {}}
                  meta={pendingItems?.meta || {}}
                  showDetailsColumn={true}
                  showCommentColumn={true}
                  selectionMode="multiselect"
                />
              </div>
              {isPanelOpen && (
                <DetailsPanel showActions={true} isMaximised={false} />
              )}

              <div
                style={{ paddingLeft: "15px", paddingTop: "20px" }}
                className={styles.wrapper}
              >
                <Button
                  disabled={selectedItems.length < 1 || isLoading}
                  onClick={() => approveRejectItems(ActionName.Approve)}
                  icon={<Checkmark32Filled />}
                  appearance="primary"
                >
                  Approve selected
                </Button>
                <Button
                  disabled={selectedItems.length < 1 || isLoading}
                  onClick={() => approveRejectItems(ActionName.Reject)}
                  icon={<Dismiss32Filled />}
                >
                  Reject selected
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
