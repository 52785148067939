import {
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  TableColumnDefinition,
  TableRowId,
  DataGridProps,
} from "@fluentui/react-components";
import { globalStateService } from "../../services/services";
import React, { useEffect } from "react";
import { GenerateDynamicColumns } from "./DynamicColumns";
import { DataItem, MetaField } from "../../models/ApiResponse";
import { SelectionMode as SelectionMode_2 } from '@fluentui/react-utilities';
interface DynamicTableProps {
  data: DataItem[];
  meta: Record<string, MetaField>;
  showDetailsColumn: boolean;
  showCommentColumn: boolean;
  selectionMode: SelectionMode_2;
}

export const DynamicTable: React.FC<DynamicTableProps> = ({ data, meta, showDetailsColumn, showCommentColumn, selectionMode }) => {
  const [selectedRows, setSelectedRows] = React.useState(
    new Set<TableRowId>([])
  );

  useEffect(() => {
    setSelectedRows(new Set<TableRowId>([]));
  }, [data]);

  const visibleColumns = Object.values(meta).filter((column) => column.visible);  

  const onSelectionChange: DataGridProps["onSelectionChange"] = (e, data) => {
    setSelectedRows(data.selectedItems);
    const values = Array.from(data.selectedItems.values());
    globalStateService.SetSelectedItems(values);
  };

  const onItemChanged = (item: DataItem, fieldName: string, newValue: any) => {
    const ItemIndex = data.findIndex(
      (d) => d["costItemId"].value === item["costItemId"].value
    );

    if(ItemIndex >= 0) {
      //const updatedItems = items;
      const updatedItem = data[ItemIndex];
      updatedItem[`${fieldName}`].value = newValue;
      data[ItemIndex] = updatedItem;      
      //setItems(updatedItems);
    }
  }

  const columns: TableColumnDefinition<DataItem>[] = GenerateDynamicColumns(visibleColumns,showDetailsColumn,showCommentColumn,onItemChanged);
  
  return (
    <DataGrid
      items={data}
      columns={columns}
      sortable
      selectionMode={selectionMode}
      onSelectionChange={onSelectionChange}
      selectedItems={selectedRows}
      getRowId={(item) => item["costItemId"]?.value}
      focusMode="composite"
    >
      <DataGridHeader>
        <DataGridRow
          selectionCell={{
            invisible: selectionMode === "single",
            checkboxIndicator: { "aria-label": "Select all rows" },
          }}
        >
          {({ renderHeaderCell }) => (
            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
          )}
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody<DataItem>>
        {({ item, rowId }) => (
          <DataGridRow<DataItem>
            key={rowId}
            selectionCell={{
              invisible: selectionMode === "single",
              checkboxIndicator: { "aria-label": "Select row" },
            }}
          >
            {({ renderCell }) => (
              <DataGridCell>{renderCell(item)}</DataGridCell>
            )}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>
  );
};
