import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { globalStateService } from "../../services/services";
import { DetailsPanel } from "../DetailsPanel/DetailsPanel";
import useObservable from "../../hooks/useObservable";

export const CostItemDetails = () => {
    let { id } = useParams();
    const isPanelOpen = useObservable(globalStateService.isDetailsPanelOpen);
    useEffect(() => {
      if(id) {        
        globalStateService.SetPanelDetailsOpen(true);        
        globalStateService.SetCurrentCostItemId(id);
      }
    },[id]);
  return (
    <>
     {id && isPanelOpen && <DetailsPanel showActions={false} isMaximised={true} />} 
    </>
  );
};
