import {
  Dropdown,
  makeStyles,
  Option,
  useId,
} from "@fluentui/react-components";
import type { DropdownProps } from "@fluentui/react-components";
import { globalStateService } from "./../services/services";
import { CostItemsView } from "../models/CostItemView";
const useStyles = makeStyles({
  root: {
  },
});

export interface IViewDropdownProps {
  defaultSelectedOptions: string[];
  dropDownProps?: Partial<DropdownProps>;
}

export const ViewDropdown: React.FC<IViewDropdownProps> = ({
  defaultSelectedOptions,
  dropDownProps,
}) => {
  const dropdownId = useId("dropdown-default");
  const options = [
    `${CostItemsView.All}`,
    `${CostItemsView.Pending}`,
    `${CostItemsView.Approved}`,
    `${CostItemsView.Rejected}`,
  ];
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <Dropdown
        defaultSelectedOptions={defaultSelectedOptions}
        aria-labelledby={dropdownId}
        onOptionSelect={(event, data) =>
          globalStateService.SetCostItemsView(
            (data.optionText || CostItemsView.All) as CostItemsView
          )
        }
        placeholder="Select a view"
        {...dropDownProps}
      >
        {options.map((option) => (
          <Option key={option}>{option}</Option>
        ))}
      </Dropdown>
    </div>
  );
};
