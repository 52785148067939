import { app } from "@microsoft/teams-js";
import { useNavigate } from "react-router-dom";

export default function Tab() {
  const navigate = useNavigate();

  app.getContext().then((context) => {
    const subEntityId = context.page.subPageId;
    if (subEntityId) {
      navigate(`/costitemdetails/${subEntityId}`);
    } else {
      navigate("/pendingItems");
    }
  });
  return <></>;
}
